import styled from "styled-components"
import { device } from "../../utils/screenSizes"

export const NoDataStyled = styled.div`
  font-family: "Bloomberg Arabic Beta v4";
  font-weight: 400;
  font-size: 16px;
  line-height: 16px;
  color: #616161;
  text-align: center;
  width: 100%;
`

export const ToolbarStyled = styled.div`
  position: fixed;
  left: 0;
  bottom: 0;
  z-index: 1004;
  background-color: #6d7f87;
  width: 60px;
  height: 60px;
  color: #ffffff;
  cursor: pointer;
`

export const BodyTitleStyled = styled.div`
  height: 60px;
  background-color: #ffffff;
  display: flex;
  justify-content: space-between;
  align-items: center;
  box-shadow: rgba(0, 0, 0, 0.1) 0px 10px 10px;
  padding-right: 20px;
  padding-left: 20px;
  position: sticky;
  top: 0;
  z-index: 1005;

  @media only screen and (min-device-width: 320px) and (max-device-width: 568px) and (-webkit-min-device-pixel-ratio: 2) and (orientation: landscape) {
    height: 42px;
  }
`
export const ToolbarBackGround = styled.div`
  width: 100%;
  position: fixed;
  z-index: 1003;
  top: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.3);
  min-height: 100vh;
`

export const ContentStyled = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fill, 145px);
  grid-gap: 30px;
  flex-wrap: wrap;
  padding: 20px 30px;
  justify-content: space-between;

  @media ${device.s} {
    justify-content: space-around;
    grid-gap: 30px 20px;
    padding: 30px 20px;
  }
`

export const BodyStyled = styled.div`
  position: fixed;
  left: 0;
  bottom: 0;
  z-index: 1005;
  background-color: #f8f8f8;
  width: 100%;
  height: ${({ viewportHeight }) => Math.min(viewportHeight - 70, 340)}px;
  overflow-y: auto;
  max-height: calc(100vh - 78px);

  @media only screen and (min-device-width: 320px) and (max-device-width: 1080px) and (-webkit-min-device-pixel-ratio: 2) and (orientation: landscape) {
    min-height: 200px;
  }
`
export const TabButtons = styled.div`
  max-width: 150px;
  margin-right: 10px;
  margin-left: 10px;
  color: ${props => (props.active ? "#253339" : "#6E6E6E")};
  font-size: 16px;
  cursor: pointer;
  font-family: "Bloomberg Arabic Beta v4";
`

export const Article = styled.div`
  width: 145px;

  a {
    text-decoration: none;
  }
`

export const Image = styled.div`
  height: 152px;
  width: 145px;
`

export const TabText = styled.div`
  @media ${device.s} {
    display: none;
  }
`

export const TabIcon = styled.img`
  display: none;

  @media ${device.s} {
    display: inline;
  }
`

export const TabName = styled.div`
  font-family: "Bloomberg Arabic Beta v4";
  margin-top: 30px;
  display: none;
  margin-right: 30px;
  font-weight: 700;
  font-size: 20px;
  color: #253339;

  @media ${device.s} {
    display: block;
  }
`

export const TabItems = styled.div`
  display: flex;

  @media ${device.s} {
    justify-content: space-between;
    width: 40%;
  }
`

export const ToolUpIcon = styled.img`
  margin-top: 20px;
  margin-left: 20px;
  height: 20px;
  width: 20px;
  alt: "^";
`

export const CloseIcon = styled.img`
  alt: "x";
  height: 12px;
  width: 12px;
  margin-left: 10px;
  @media only screen and (min-device-width: 780px) and (max-device-width: 1080px) and (-webkit-min-device-pixel-ratio: 2) and (orientation: landscape) {
    margin-left: 20px;
  }
`

export const CloseClick = styled.div`
  padding-top: 4px;
  padding-left: 4px;
  height: 30px;
  width: 30px;
  cursor: pointer;
`

export const HeadlineText = styled.div`
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #acacac;
  font-family: "Bloomberg Arabic Beta v4";
  font-weight: bold;
  font-size: 24px;
`
