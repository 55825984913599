import { Link } from 'gatsby'
import { connect } from 'react-redux'
import InfiniteScroll from 'react-infinite-scroll-component'
import React, { useState, useEffect } from 'react'
import { useOrientation } from 'react-use'
import Ellipsis from 'react-text-ellipsis'

import {
  ToolbarStyled,
  BodyStyled,
  NoDataStyled,
  BodyTitleStyled,
  ContentStyled,
  Article,
  Image,
  TabText,
  TabIcon,
  TabItems,
  TabButtons,
  TabName,
  ToolUpIcon,
  CloseIcon,
  CloseClick,
  ToolbarBackGround
} from './toolbar.styled.js'
import {
  closeIcon,
  bookmarkIcon,
  chevronIcon as toolbarIcon,
  heartIcon,
  historyIcon
} from './assets'
import BackgroundCover from '../background/background'
import useViewportSize from '../../utils/useViewportSize'

const HEADLINE_DEFAULT_IMAGE =
  'https://img-staging.asharq.com/30931574-cdd2-4bed-85e6-9d6b8b8d8933.png?w=145&h=152&dpr=2&fit=crop&ixlib=js-2.3.1&s=c44fc81b29aea368b4ef04e57cf511a9'

const tabs = [
  { key: 'bookmark', icon: bookmarkIcon },
  { key: 'favorite', icon: heartIcon },
  { key: 'history', icon: historyIcon }
]

const ToolbarBody = (props) => {
  const {
    toolbarArticles,
    getArticles,
    fetching,
    toolbarOpen,
    toggleToolbar,
    page,
    hasMore
  } = props

  const [activeTab, setActiveTab] = useState('history')
  const { height: viewportHeight } = useViewportSize()
  const isClient = typeof window === 'object'
  const orientation = isClient ? useOrientation() : {}

  const getSize = () => {
    let size = 0

    if (typeof window !== 'undefined') {
      const itemsPerRow = Math.floor((window.innerWidth - 30) / 175)

      if (itemsPerRow < 8) {
        while (size < 8) {
          size += itemsPerRow
        }
      } else {
        size = itemsPerRow * 2
      }
    }

    return size
  }

  useEffect(() => {
    document.body.classList[toolbarOpen ? 'add' : 'remove']('prevent-scroll')

    if (toolbarOpen) {
      fetchData({ page: 1 })
    }
  }, [toolbarOpen])

  const fetchData = ({ tabName, ...params } = {}) => {
    getArticles(
      { lang: 'ar', size: getSize(), page: page + 1, ...params },
      (tabName || activeTab).toUpperCase()
    )
  }

  const clickTab = (tabName) => {
    const div = document.getElementsByClassName('infinite-scroll-component')[0]
    div.scroll(0, 0)
    setActiveTab(tabName)
    fetchData({ page: 1, tabName })
  }

  const NoData = () => (
    <NoDataStyled style={{ marginTop: '100px' }}>لايوجد معروضات</NoDataStyled>
  )

  const renderLoader = () => (
    <NoDataStyled
      style={{ marginTop: toolbarArticles.length ? '0px' : '100px' }}
    >
      <div className="lds-toolbar">
        <div></div>
        <div></div>
        <div></div>
        <div></div>
      </div>
    </NoDataStyled>
  )

  const getArticleLink = (article) => {
    if (article.type === 'headline') {
      return `/latest-news/${article.id}/`
    }

    if (article.shortUrl) {
      return article.shortUrl
    }

    return article.articleType === 'shorthand'
      ? `/specials/${article.slug}`
      : `/ar/${article.slug}`
  }

  if (!toolbarOpen) {
    return (
      <ToolbarStyled onClick={toggleToolbar}>
        <ToolUpIcon src={toolbarIcon} alt="Open toolbar" />
      </ToolbarStyled>
    )
  }

  return (
    <>
      <ToolbarBackGround onClick={toggleToolbar} />

      <BodyStyled viewportHeight={viewportHeight}>
        <BodyTitleStyled>
          <CloseClick onClick={toggleToolbar}>
            <CloseIcon src={closeIcon} />
          </CloseClick>

          <TabItems>
            {tabs.map((tab) => (
              <TabButtons
                key={tab.key}
                onClick={() => clickTab(tab.key)}
                active={activeTab === tab.key}
              >
                <TabText> {tabNameAr[tab.key]}</TabText>
                <TabIcon src={tab.icon} />
              </TabButtons>
            ))}
          </TabItems>
        </BodyTitleStyled>

        <div dir="rtl">
          <InfiniteScroll
            style={{ overflowX: 'hidden' }}
            className="toolbar-list"
            height={280}
            dataLength={toolbarArticles.length}
            next={() => {
              fetchData()
            }}
            hasMore={hasMore}
            loader={renderLoader()}
          >
            <TabName>{tabNameAr[activeTab]}</TabName>

            {!fetching && toolbarArticles.length === 0 && NoData()}

            <ContentStyled>
              {toolbarArticles.map((article) => {
                return (
                  <Article
                    key={`${article.id}-${orientation.angle}`}
                    onClick={toggleToolbar}
                  >
                    <Link to={getArticleLink(article)}>
                      <Image>
                        <BackgroundCover
                          title={article.title}
                          articleId={article.id}
                          src={
                            article.type === 'headline'
                              ? HEADLINE_DEFAULT_IMAGE
                              : article.mainImageUrl
                          }
                          disableVideoClick
                          imageQuality={90}
                          imageDpr={2}
                          videoSrc={article.mainVideoUrl}
                          forceVideo={false}
                          fullScreen={false}
                          imageMeta={article.mainImage}
                          disableControls={true}
                          autoplay={false}
                          lazyload={true}
                          imageUrl={
                            article.type === 'headline'
                              ? HEADLINE_DEFAULT_IMAGE
                              : null
                          }
                          style={
                            article.type === 'headline'
                              ? { backgroundColor: 'rgb(235, 235, 235)' }
                              : {}
                          }
                        ></BackgroundCover>
                      </Image>
                      <Ellipsis
                        lines={2}
                        tag={'p'}
                        ellipsisChars={'...'}
                        debounceTimeoutOnResize={200}
                        useJsOnly={true}
                        style={{
                          color: '#616161',
                          marginTop: 10,
                          fontSize: 16
                        }}
                      >
                        {article.title}
                      </Ellipsis>
                    </Link>
                  </Article>
                )
              })}
            </ContentStyled>
          </InfiniteScroll>
        </div>
      </BodyStyled>
    </>
  )
}

const tabNameAr = {
  history: 'تاريخ التصفح',
  favorite: 'مفضل',
  bookmark: 'المختارات',
  saved: 'البحث والنتائج'
}

const mapDispatchToProps = (dispatch) => {
  return {
    getArticles: (payload, tabName) => {
      dispatch({ type: 'TOOLBAR_ARTICLES_REQUESTED', payload, tabName })
    },
    toggleToolbar: () => {
      dispatch({ type: 'TOGGLE_TOOLBAR' })
    }
  }
}

const mapStateToProps = ({ toolbar }) => {
  const { toolbarData, fetching, toolbarOpen, page, hasMore } = toolbar

  return { toolbarArticles: toolbarData, fetching, toolbarOpen, page, hasMore }
}

export default connect(mapStateToProps, mapDispatchToProps)(ToolbarBody)
